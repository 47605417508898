import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/content/PageTitle"

const Cookies = () => {
  return (
    <Layout>
      <Seo title="Cookies - Mitchells & Butlers" description="" />

      <PageTitle title="Cookies" />

      <div className={`container`}>
        <div className={`w-full mx-auto md:w-3/4 lg:w-1/2 pb-10 md:pb-32`}>

          <h2>HOW WE USE COOKIES ON THIS WEBSITE</h2>
          <p>A cookie is a small text file comprised of numbers and letters that a website saves on your computer or mobile device when you visit the site. It enables our website to remember your actions and preferences over a period of time, which helps us provide the best user experience to you on our sites. Cookies also make our websites work appropriately.</p>

          <h3>HOW WE USE COOKIES</h3>
          <p>We use cookies and related technologies to understand and improve our websites, systems and your experience, including personalising content, adverts and social media features. We do not use cookies to collect personal information about you.</p>

          <p>Most cookies store data in relation to the current brand/website which a user is interacting with. However, some cookies, which are identified belong as “global” cookies, have the facility to track data across the user's use of the websites of our other M&B brands (a list of brands and outlets can be found at <a href="https://www.mbplc.com">www.mbplc.com</a>). Please be assured that such cross-brand tracking is only utilised if the user has provided cookie consent to each of the brands individually. More detail on the cookies with this facility and the purposes of such tracking is set out below.</p>

          <h3>Essential Cookies</h3>
          <p>We are allowed to store cookies on your computer which are essential for our website to function. No permission is required for these cookies.</p>

          <p>These are required for our website to function and these cookies let you use all the different parts of our websites. Without them, services that you've asked for can't be provided. Some examples of how we use these cookies are:</p>

          <h4>Signing into your account</h4>
          <p>Remembering actions you've already taken, such as text you've entered into a form, so it'll still be there when you go back to the page in the same web session.</p>
          <p>Remembering security settings that affect access to certain content.</p>

          <h4>Non-Essential Cookies</h4>
          <p>For all non-essential cookies, we rely on your permission which is obtained by clicking “Allow All” on our cookie banner or via our “Manage Cookies” Page. Your cookie preference shall remain valid until either (i) your preference is updated by you, or (ii) 6 months has passed since you gave your consent; after such time the Cookies banner will appear on your next visit to our website and you will be required to confirm your cookie preferences again. Furthermore, you can access the “Manage Cookies” below at any time to update your preference. Acceptance or withdrawal of consent for non-essential cookies above will change consent for all types of non-essential cookies.</p>

          <h3>The Non-Essential Cookies utilised on our website are as follows:</h3>

          <h4>Experience</h4>
          <p>These cookies consist of functional and analytical cookies.</p>

          <p>Functional cookies recognise you when you return to our website, enabling us to personalise content and remember preferences (e.g. your region).</p>

          <p>Analytical cookies allow us to recognise and count visitor numbers and see how they navigate the website. This helps us identify improvements; e.g. speeding up searches.</p>

          <h4>Advertisement</h4>
          <p>These cookies record your visit to our website (e.g. links followed, pages visited) and allow us to show you more relevant content and advertisements and to create a profile of what we think might interest you. We may also share this information with third parties for this purpose.</p>

          <p>By clicking to agree to our storage of cookies on your device and/or the continued use of our websites, you give us permission to use the types of cookies set out below, in the manner and for the purposes described; see "Cookies We Set".</p>

          <p>You may refuse to accept both essential and non-essential cookies by activating the setting on your browser which allows you to refuse the setting of cookies; see "Manage Cookies" below for guidance. However, if you select this setting, you may be unable to access certain parts of our site. Unless you have adjusted your browser settings so that it will refuse cookies, our system will issue essential cookies when you log on to our site.</p>

          <h3>COOKIES WE SET</h3>

          <h4>_GA, _GA_*, _utm*</h4>
          <ul>
            <li><strong>Domain:</strong> supplierdinewithus.co.uk</li>
            <li><strong>Type:</strong> Analytics</li>
            <li><strong>Description:</strong> This cookie is installed by Google Analytics. The cookie is used to calculate visitor, session, campaign data and keep track of site usage for the site's analytics report. The cookies store information anonymously and assign a randomly generated number to identify unique visitors.</li>
            <li><strong>Duration:</strong> 2 years</li>
            <li><strong>Essential or Non-Essential:</strong> Non-Essential</li>
          </ul>

          <h4>dtCookie, dtLatC, dtPC, dtSa, rxVisitor, rxvt</h4>
          <ul>
            <li><strong>Domain:</strong> mbplc.com </li>
            <li><strong>Type:</strong> Analytics </li>
            <li><strong>Description:</strong> This cookie is installed by Dynatrace. The cookie is used for guest experience, behaviour and website performance. The cookies store information anonymously and assign a randomly generated number to identify unique visitors. </li>
            <li><strong>Duration:</strong> Length of guest session </li>
            <li><strong>Essential or Non-Essential:</strong> Non-Essential </li>
          </ul>

          <h4>_cm_global_id</h4>
          <ul>
            <li><strong>Domain:</strong> mab.api.uk.clicksandmortar-production.com</li>
            <li><strong>Type:</strong> Analytics</li>
            <li><strong>Description:</strong> This cookie is installed by Clicks & Mortar, one of our technology providers, to anonymously store a randomly generated number, facilitating the understanding of visitor, session, and campaign data. It enables personalised website experiences for returning visitors and overall user experience improvement. Additionally, the cookies allow tracking of preferences and online behaviour across M&B Brands and multiple devices, enhancing the ability to tailor experiences based on guests' preferences and online behaviour.</li>
            <li><strong>Duration:</strong> 12 months</li>
            <li><strong>Essential or Non-Essential:</strong> Non-Essential</li>
          </ul>

          <h4>Cookie Control</h4>
          <ul>
            <li><strong>Domain:</strong> supplierdinewithus.co.uk </li>
            <li><strong>Type:</strong> Functional </li>
            <li><strong>Description:</strong> This cookie is used to inform users about cookies and their purpose and who sets them, and aids in obtain consent for non-essential cookies via an explicit mechanism such as a button, toggle, or checkbox. </li>
            <li><strong>Duration:</strong> 2 years </li>
            <li><strong>Essential or Non-Essential:</strong> Non essential </li>
          </ul>

          <h3>MANAGE COOKIES</h3>

          <p>If you wish to block or restrict cookies set by this or any other website you can do so through your web browser's settings. The "Help" function (often in the main menu options at the top of your browser screen, and usually accessible by pressing the F1 key in Windows) should tell you how to do so.</p>

          <p>Many browsers give a range of options for managing cookies, including obtaining consent for every cookie, blocking certain types of cookie and blocking cookies from unrecognised websites. You can set your browser to block all cookies; however, you should be aware that many sites will not work as smoothly as you are used to and some basic functionality that is dependent on cookies will not work at all.</p>

          <p>The following links go to the relevant help sections for the major web browsers and explain how to manage your cookies:</p>

          <ul>
            <li>Chrome - <a href="https://support.google.com/chrome/bin/answer.py?hl=en&answer=95647&p=cpn_cookies" target="_blank" rel="noreferrer">https://support.google.com/chrome/bin/answer.py?hl=en&answer=95647&p=cpn_cookies</a></li>
            <li>Safari iOS - <a href="http://support.apple.com/kb/HT1677" target="_blank" rel="noreferrer">http://support.apple.com/kb/HT1677</a></li>
            <li>Internet Explorer - <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noreferrer">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
            <li>Firefox - <a href="http://support.mozilla.org/en-US/kb/Cookies?s=cookies&r=0&e=es&as=s" target="_blank" rel="noreferrer">http://support.mozilla.org/en-US/kb/Cookies?s=cookies&r=0&e=es&as=s</a></li>
          </ul>

          <h3>MANAGE COOKIES</h3>
          <p>The types of cookies used on our website are summarised below but for more information see our Cookie Policy and Privacy Policy. You can change or withdraw your consent by amending your preferences using the cookie settings accessed by clicking the small c in the lower left corner of your browser. </p>

          <h4>Necessary Cookies</h4>

          <p>Necessary cookies enable core functionality such as page navigation and access to secure areas. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.</p>

          <h4>Analytical Cookies</h4>
          <p>Analytical cookies help us to improve our website by collecting and reporting information on its usage. They allow us to recognise and count visitor numbers and see how they navigate the website. This helps us identify improvements; e.g. speeding up searches.</p>

          <h4>Marketing cookies </h4>
          <p>Marketing cookies record your visit to our website (e.g. links followed, pages visited) and allow us to show you more relevant content and advertisements and to create a profile of what we think might interest you. We may also share this information with third parties for this purpose.</p>

          <p>We use cookies and related technologies to understand and improve our systems and your experience, including personalising content, adverts and social media features. You can find out more information in our Cookie Policy above and our <a href="https://www.mbplc.com/privacy/" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>
        </div>
      </div>
    </Layout>
  )
}

export default Cookies
